/** @jsx jsx */
import { jsx, Box, Label, Input, Flex, Text, Spinner, Select } from "theme-ui";
import React, { useEffect, useState } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles, inputStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { get } from "lodash";
import handleError from "sites-common/utils/handleError";
import useFormValidations from "../utils/useFormValidations";

const SectorCategoryAddForm = ({
  refreshTemplates = () => {},
  closeModal = () => {},
  initValue = {},
}) => {
  const formSchema = {
    id: { value: get(initValue, "id", null), error: "" },
    name: { value: get(initValue, "name", ""), error: "" },
    sector: { value: get(initValue, "sector", initValue?.sector), error: "" },
    sequence: { value: get(initValue, "sequence", ""), error: "" },
    active: { value: get(initValue, "active", "true"), error: "" },
  };

  const formValidatorSchema = {
    name: {
      required: true,
      error: "Name can't be empty",
    },
    sector: { required: true, error: "Sector can't be empty" },
    sequence: { required: true, error: "Sequence can't be empty" },
  };

  const [updating, setUpdating] = useState(false);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();
  const [sectorData, setsectorData] = useState([]);

  useEffect(() => {
    fetchSrcmApi({
      api: `/connect/v3/sectors/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        page_size: 1000,
        active: true,
      },
    }).then((data) => {
      const optionsArr = data.results.map((item) => {
        return { key: item.id, text: item.name };
      });
      setsectorData(optionsArr);
    });
  }, [fetchSrcmApi]);

  const submitFormCallback = (data) => {
    const tempBody = data;
    setUpdating(true);
    const afterUpdate = () => {
      const alertInfo = {
        title: "Success",
        message: `Category has been successfully ${
          tempBody && tempBody.id ? "updated" : "created"
        }.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      refreshTemplates();
      closeModal();
      setUpdating(false);
      showAlert(alertInfo);
    };
    if (tempBody.id) {
      const sectorId = tempBody?.sector?.id || tempBody?.sector;
      delete tempBody?.sector;
      tempBody.sector = sectorId;
    }
    let apiUrl = `/connect/v3/categories/`;
    if (tempBody && tempBody.id) {
      apiUrl = `/connect/v3/categories/${tempBody.id}/`;
    } else {
      delete tempBody.id;
    }

    fetchSrcmApi({
      api: apiUrl,
      method: tempBody && tempBody.id ? "PUT" : "POST",
      methodParams: tempBody,
      client: "eventsClient",
    })
      .then(() => {
        afterUpdate();
      })
      .catch((error) => {
        const alertInfo = {
          title: "Error",
          message: handleError(error),
          confirm_text: "Okay",
          is_blocking: true,
        };
        closeModal();
        setUpdating(false);
        showAlert(alertInfo);
      });
  };

  const { values, errors, dirty, disable, handleOnChange, handleOnSubmit } =
    useFormValidations(formSchema, formValidatorSchema, submitFormCallback);

  const { name, sequence, sector, active } = values;

  return (
    <React.Fragment>
      <Box>
        <Box mb={3}>
          <Label htmlFor="name" mb={2}>
            Name <span sx={inputStyles.required}>*</span>
          </Label>
          <Input
            sx={inputStyles.inputStyle}
            style={errors.name && dirty.name ? inputStyles.inputError : null}
            name="name"
            id="name"
            value={name}
            onChange={handleOnChange}
          />
          {errors.name && dirty.name && (
            <Text sx={inputStyles.errorMessage}>{errors.name}</Text>
          )}
        </Box>
        <Box mb={3}>
          <Label htmlFor="sector" mb={2}>
            Sector <span sx={inputStyles.required}>*</span>
          </Label>
          <Select
            sx={inputStyles.inputStyle}
            style={
              errors.sector && dirty.sector ? inputStyles.inputError : null
            }
            name="sector"
            id="sector"
            value={sector?.id}
            onChange={handleOnChange}
          >
            <option value="">Please select sector</option>
            {sectorData.map((option) => (
              <option value={option.key}>{option.text}</option>
            ))}
          </Select>
          {errors.sector && dirty.sector && (
            <Text sx={inputStyles.errorMessage}>{errors.sector}</Text>
          )}
        </Box>
        <Box mb={3}>
          <Label htmlFor="sequence" mb={2}>
            Sequence <span sx={inputStyles.required}>*</span>
          </Label>
          <Input
            type="number"
            sx={inputStyles.inputStyle}
            style={
              errors.sequence && dirty.sequence ? inputStyles.inputError : null
            }
            name="sequence"
            id="sequence"
            placeholder="Please enter a numeric value"
            value={sequence}
            onChange={handleOnChange}
          />
          {errors.sequence && dirty.sequence && (
            <Text sx={inputStyles.errorMessage}>{errors.sequence}</Text>
          )}
        </Box>
        <Box mb={3}>
          <Label htmlFor="active" mb={2}>
            Status
          </Label>
          <Select
            sx={inputStyles.inputStyle}
            style={
              errors.active && dirty.active ? inputStyles.inputError : null
            }
            name="active"
            id="active"
            value={active}
            onChange={handleOnChange}
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Select>
          {errors.active && dirty.active && (
            <Text sx={inputStyles.errorMessage}>{errors.active}</Text>
          )}
        </Box>
        <Flex sx={{ gap: 3, float: "right", pb: 3 }}>
          {updating && <Spinner size={35} />}
          <DefaultButton
            disabled={disable || updating}
            styles={buttonStyles.default}
            onClick={closeModal}
          >
            Cancel
          </DefaultButton>
          <DefaultButton
            disabled={disable || updating}
            styles={buttonStyles.blueLight}
            onClick={handleOnSubmit}
          >
            Submit
          </DefaultButton>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

SectorCategoryAddForm.defaultProps = {
  initValue: {},
};

SectorCategoryAddForm.propTypes = {
  refreshTemplates: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  initValue: PropTypes.objectOf(PropTypes.any),
};

export default React.memo(SectorCategoryAddForm);
