/** @jsx jsx */
import React, { useState, useEffect, useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import PropTypes, { number } from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { get } from "lodash";
import {
  ActionButton,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogType,
  SelectionMode,
} from "office-ui-fabric-react";
import Pagination from "../../utils/pagination";
import { appsDialogStyles } from "../../utils/dialogStyles";
import EventTypeManagementAddForm from "../EventTypeManagementAddForm";

const EventTypeManagement = ({ renderSectorItemColumn }) => {
  const [addEventTypeDialog, setAddEventTypeDialog] = useState(false);
  const [editTemplate, setEditTemplate] = useState({});
  const openAddEventTypeDialog = () => setAddEventTypeDialog(true);
  const [eventTypeData, setEventTypeData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchSrcmApi } = useFetchSrcmApi();

  const closeEventTypeDialog = () => {
    setEditTemplate({});
    setAddEventTypeDialog(false);
  };

  const eventTypeInitColumns = [
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sequence",
      fieldName: "sequence",
      name: "Sequence",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "active",
      fieldName: "active",
      name: "Status",
      data: Boolean,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddEventTypeDialog();
            }}
          />
        </Flex>
      ),
    },
  ];
  const getEventTypeManagement = useCallback(() => {
    fetchSrcmApi({
      api: `/connect/v3/event-types/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        page: `${page}`,
        page_size: `${pageSize}`,
      },
    }).then((data) => {
      setEventTypeData(data);
    });
  }, [fetchSrcmApi, pageSize, page]);
  useEffect(() => {
    getEventTypeManagement();
  }, [getEventTypeManagement, pageSize, page]);
  return (
    <>
      <Box sx={{ p: 2, textAlign: "right" }}>
        <DefaultButton
          styles={buttonStyles.blueLight}
          onClick={openAddEventTypeDialog}
        >
          Add Event{" "}
        </DefaultButton>
      </Box>
      <Box>
        <Pagination
          page={page - 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          TotalPageCount={eventTypeData.count}
          setPage={setPage}
        />
        {eventTypeData &&
        eventTypeData.results &&
        eventTypeData.results.length ? (
          <DetailsList
            columns={eventTypeInitColumns}
            items={eventTypeData.results}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderSectorItemColumn}
            compact
            enableUpdateAnimations
          />
        ) : (
          ""
        )}
      </Box>
      <Dialog
        isOpen={addEventTypeDialog}
        type={DialogType.close}
        onDismiss={closeEventTypeDialog}
        title={get(editTemplate, "id") ? "Edit Event " : "Add Event "}
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <EventTypeManagementAddForm
            refreshTemplates={getEventTypeManagement}
            closeModal={closeEventTypeDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>
    </>
  );
};

EventTypeManagement.propTypes = {
  renderSectorItemColumn: PropTypes.func.isRequired,
};

export default React.memo(EventTypeManagement);
