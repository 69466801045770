/** @jsx jsx */
import React, { useState, useEffect, useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { number, PropTypes } from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { get } from "lodash";
import {
  ActionButton,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogType,
  Dropdown,
  SelectionMode,
} from "office-ui-fabric-react";
import Pagination from "../../utils/pagination";
import { appsDialogStyles } from "../../utils/dialogStyles";
import SectorCategoryAddForm from "../SectorCategoryAddForm";

const SectorCategoryManagement = ({ renderSectorItemColumn }) => {
  const [sectorsCategoryData, setSectorsCategoryData] = useState([]);
  const [addSectorCategoryDialog, setAddSectorCategoryDialog] = useState(false);
  const openAddSectorCategoryDialog = () => setAddSectorCategoryDialog(true);
  const [editTemplate, setEditTemplate] = useState({});

  const closeAddSectorCategoryDialog = () => {
    setEditTemplate({});
    setAddSectorCategoryDialog(false);
  };
  // Pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchSrcmApi } = useFetchSrcmApi();

  // filterdata
  const intialFilterData = [{ key: "All", text: "All" }];
  const [filtersData, setFiltersData] = useState(intialFilterData);
  const [methodParams, setMethodParams] = useState();

  const sectorCategoryInitColumns = [
    {
      key: "name",
      fieldName: "name",
      name: "Category Name",
      data: "string",
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "sector",
      fieldName: "sector",
      name: "Sector Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },

    {
      key: "sequence",
      fieldName: "sequence",
      name: "Sequence",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "active",
      fieldName: "active",
      name: "Status",
      data: Boolean,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddSectorCategoryDialog();
            }}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    fetchSrcmApi({
      api: `/connect/v3/sectors/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        active: true,
        page_size: 1000,
      },
    }).then((data) => {
      const optionsArr = data.results.map((item) => {
        return { key: item.id, text: item.name };
      });

      setFiltersData((prevState) => [...prevState, ...optionsArr]);
    });
  }, [fetchSrcmApi]);

  const getAllSectorCategoryTemplates = useCallback(() => {
    fetchSrcmApi({
      api: `/connect/v3/categories/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        ...methodParams,
        page: `${page}`,
        page_size: `${pageSize}`,
      },
    }).then((data) => {
      setSectorsCategoryData(data);
    });
  }, [fetchSrcmApi, pageSize, page, methodParams]);

  useEffect(() => {
    getAllSectorCategoryTemplates();
  }, [getAllSectorCategoryTemplates]);

  const onChange = (e, v) => {
    if (!(v.key === "All")) {
      setMethodParams({ sector: v.key });
    } else {
      setMethodParams();
    }
    setPage(1);
  };

  return (
    <>
      <Box sx={{ p: 2, textAlign: "right" }}>
        <DefaultButton
          styles={buttonStyles.blueLight}
          onClick={openAddSectorCategoryDialog}
        >
          Add Category{" "}
        </DefaultButton>
      </Box>
      <Box>
        <Pagination
          page={page - 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          TotalPageCount={sectorsCategoryData.count}
          setPage={setPage}
        />
        <Dropdown
          style={{ maxWidth: "150px" }}
          options={filtersData}
          sx={{ ml: 2 }}
          placeholder="Filter by Sector"
          onChange={onChange}
        />

        {sectorsCategoryData &&
        sectorsCategoryData.results &&
        sectorsCategoryData.results.length ? (
          <DetailsList
            columns={sectorCategoryInitColumns}
            items={sectorsCategoryData.results}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderSectorItemColumn}
            compact
            enableUpdateAnimations
          />
        ) : (
          ""
        )}
      </Box>
      <Dialog
        isOpen={addSectorCategoryDialog}
        type={DialogType.close}
        onDismiss={closeAddSectorCategoryDialog}
        title={get(editTemplate, "id") ? "Edit Category " : "Add Category "}
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <SectorCategoryAddForm
            refreshTemplates={getAllSectorCategoryTemplates}
            closeModal={closeAddSectorCategoryDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>
    </>
  );
};

SectorCategoryManagement.propTypes = {
  renderSectorItemColumn: PropTypes.func.isRequired,
};

export default React.memo(SectorCategoryManagement);
