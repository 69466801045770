/** @jsx jsx */
import React, { useState, useEffect, useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import { number, PropTypes } from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { get } from "lodash";
import {
  ActionButton,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogType,
  SelectionMode,
} from "office-ui-fabric-react";
import Pagination from "../../utils/pagination";
import { appsDialogStyles } from "../../utils/dialogStyles";
import SectorAddForm from "../SectorAddForm";

const SectorManagement = ({ renderSectorItemColumn }) => {
  const [sectorsData, setSectorsData] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { fetchSrcmApi } = useFetchSrcmApi();
  const [addSectorDialog, setAddSectorDialog] = useState(false);
  const openAddSectorDialog = () => setAddSectorDialog(true);
  const [editTemplate, setEditTemplate] = useState({});

  const closeAddSectorDialog = () => {
    setEditTemplate({});
    setAddSectorDialog(false);
  };

  const sectorInitColumns = [
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "category_label",
      fieldName: "category_label",
      name: "Category Label",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "name_label",
      fieldName: "name_label",
      name: "Name Label",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sequence",
      fieldName: "sequence",
      name: "Sequence",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "active",
      fieldName: "active",
      name: "Status",
      data: Boolean,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddSectorDialog();
            }}
          />
        </Flex>
      ),
    },
  ];

  const getAllSectorTemplates = useCallback(() => {
    fetchSrcmApi({
      api: `/connect/v3/sectors/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        page: `${page}`,
        page_size: `${pageSize}`,
      },
    }).then((data) => {
      setSectorsData(data);
    });
  }, [fetchSrcmApi, pageSize, page]);

  useEffect(() => {
    getAllSectorTemplates();
  }, [getAllSectorTemplates]);

  return (
    <>
      <Box sx={{ p: 2, textAlign: "right" }}>
        <DefaultButton
          styles={buttonStyles.blueLight}
          onClick={openAddSectorDialog}
        >
          Add Sector{" "}
        </DefaultButton>
      </Box>
      <Box>
        <Pagination
          page={page - 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          TotalPageCount={sectorsData.count}
          setPage={setPage}
        />
        {sectorsData && sectorsData.results && sectorsData.results.length ? (
          <DetailsList
            columns={sectorInitColumns}
            items={sectorsData.results}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderSectorItemColumn}
            compact
            enableUpdateAnimations
          />
        ) : (
          ""
        )}
      </Box>
      <Dialog
        isOpen={addSectorDialog}
        type={DialogType.close}
        onDismiss={closeAddSectorDialog}
        title={get(editTemplate, "id") ? "Edit Sector " : "Add Sector "}
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <SectorAddForm
            refreshTemplates={getAllSectorTemplates}
            closeModal={closeAddSectorDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>
    </>
  );
};

SectorManagement.propTypes = {
  renderSectorItemColumn: PropTypes.func.isRequired,
};

export default React.memo(SectorManagement);
