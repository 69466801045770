/** @jsx jsx */
import React, { useCallback } from "react";
import { jsx, Box, Container } from "theme-ui";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Pivot, PivotItem } from "office-ui-fabric-react";
import SectorManagement from "../../components/events-heartsapp-admin/SectorManagement";
import SectorCategoryManagement from "../../components/events-heartsapp-admin/SectorCategoryManagement";
import ProgramTypeManagement from "../../components/events-heartsapp-admin/ProgramTypeManagement";
import EventTypeManagement from "../../components/events-heartsapp-admin/EventTypeManagement";
import EventsSearchData from "../../components/events-heartsapp-admin/EventsSearchData";

const Dashboard = () => {
  const pivotItemStyle = {
    "& > div": {
      "@media (max-width: 480px)": {
        display: "flex",
        flexDirection: "column",
        padding: "0px 20px 0px 20px",
      },
    },
  };

  // const renderSectorItemColumn = useCallback((item, index, column) => {
  //   const fieldContent =
  //     column && column.fieldName ? item[column.fieldName] : "";
  //   switch (column.key) {
  //     case "id":
  //       return fieldContent || "-";
  //     case "category_label":
  //       return fieldContent || "-";
  //     case "name_label":
  //       return fieldContent || "-";
  //     case "name":
  //       return fieldContent || "-";
  //     case "sequence":
  //       return fieldContent === 0 ? "0" : fieldContent || "-";
  //     case "active":
  //       return fieldContent ? "Active" : "Inactive" || "-";
  //     case "sector":
  //       return fieldContent.name || "-";
  //     default:
  //       return index;
  //   }
  // }, []);

  const returnCitiesString = (fieldContent) => {
    const citiesArray = fieldContent.filter((item) => {
      return item.active && item.city;
    });
    const justNames = citiesArray.map((item) => {
      return item.city.name;
    });
    const strCities = justNames.toString().replaceAll(",", ", ");
    return strCities;
  };

  const renderSectorItemColumn = useCallback((item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";
    switch (column.key) {
      case "id":
        return fieldContent || "-";
      case "category_label":
        return fieldContent || "-";
      case "name_label":
        return fieldContent || "-";
      case "name":
        return fieldContent || "-";
      case "sequence":
        return fieldContent === 0 ? "0" : fieldContent || "-";
      case "active":
        return fieldContent ? "Active" : "Inactive" || "-";
      case "sector":
        return fieldContent.name || "-";
      case "organization":
        return fieldContent?.name || "-";
      case "created_by_user":
        return fieldContent?.name || "-";
      case "event_locations":
        return fieldContent !== null ? returnCitiesString(fieldContent) : "-";
      case "start_date":
        return fieldContent || "-";
      case "end_date":
        return fieldContent || "-";
      default:
        return index;
    }
  }, []);

  return (
    <>
      <Container>
        <Box
          sx={{ my: 2 }}
          style={{
            borderBottom: "1px solid #dddddd",
            padding: "10px 0px 20px 0px",
            margin: " 0px 10px 0px 10px",
          }}
        >
          <Container
            as="ul"
            sx={{
              listStyle: "none",
              color: "#333333",
              pl: 0,
              mt: 2,
              "& > li+li": {
                "::before": {
                  content: "' > '",
                },
              },
            }}
          >
            <li
              sx={{
                display: "inline",
              }}
            >
              <Link sx={{ textDecoration: "none", color: "#999999" }} to="/">
                Connect Events Admin
              </Link>
            </li>
            <li sx={{ display: "inline" }}>Master Data</li>
          </Container>
        </Box>
        <Pivot style={{ padding: "0px 20px 0px 20px" }} sx={pivotItemStyle}>
          <PivotItem headerText="Sector Management">
            <SectorManagement renderSectorItemColumn={renderSectorItemColumn} />
          </PivotItem>
          <PivotItem headerText="Sector Category Management">
            <SectorCategoryManagement
              renderSectorItemColumn={renderSectorItemColumn}
            />
          </PivotItem>
          <PivotItem headerText="Program Type Management">
            <ProgramTypeManagement
              renderSectorItemColumn={renderSectorItemColumn}
            />
          </PivotItem>
          <PivotItem headerText="Event Type Management ">
            <EventTypeManagement
              renderSectorItemColumn={renderSectorItemColumn}
            />
          </PivotItem>
          <PivotItem headerText="Events Search">
            {/* <EventTypeManagement
              renderSectorItemColumn={renderSectorItemColumn}
            /> */}
            <EventsSearchData renderSectorItemColumn={renderSectorItemColumn} />
          </PivotItem>
        </Pivot>
      </Container>
    </>
  );
};

Dashboard.prototype = {
  refreshTemplates: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  initValue: PropTypes.objectOf(PropTypes.any),
};

export default React.memo(Dashboard);
