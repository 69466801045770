/** @jsx jsx */
import { jsx, Flex, Box, Input, Label } from "theme-ui";
import React, { useState, useEffect, useCallback } from "react";
import { PropTypes, number } from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import Loader from "gatsby-plugin-hfn-profile/components/ui/Loader";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DetailsList, Dropdown, SelectionMode } from "office-ui-fabric-react";
import {
  validateAbhyasiId,
  validatePhoneNumber,
  validateEmail,
} from "../../../../sites-common/utils/validations";
import { eventsSearchStyle } from "../../../../sites-common/utils/fabricStyles";
import Pagination from "../../utils/pagination";

const DynamicSearch = ({ renderSectorItemColumn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filtersData, setFiltersData] = useState();
  const [sectorData, setSectorData] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [countData, setCountData] = useState();

  const [nameSearch, setNameSearch] = useState("");
  const [sectorSearch, setSectorSearch] = useState("");
  const [organizationSearch, setOrganizationSearch] = useState("");
  const [startDateSearch, setStartDateSearch] = useState("");
  const [endDateSearch, setEndDateSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [userInput, setUserInput] = useState("");
  const [userEmailSearch, setUserEmailSearch] = useState("");
  const [userPhoneSearch, setUserPhoneSearch] = useState("");
  const [coordinatorInput, setCoordinatorInput] = useState("");
  const [coordinatorAbhyasiSearch, setCoordinatorAbhyasiSearch] = useState("");
  const [coordinatorEmailSearch, setCoordinatorEmailSearch] = useState("");
  const [coordinatorPhoneSearch, setCoordinatorPhoneSearch] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { fetchSrcmApi } = useFetchSrcmApi();

  const sectorInitColumns = [
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sector",
      fieldName: "sector",
      name: "Sector",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "organization",
      fieldName: "organization",
      name: "organization",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "created_by_user",
      fieldName: "created_by_user",
      name: "Created By User",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "event_locations",
      fieldName: "event_locations",
      name: "City",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "start_date",
      fieldName: "start_date",
      name: "Start Date",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "end_date",
      fieldName: "end_date",
      name: "End Date",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "active",
      fieldName: "active",
      name: "Status",
      data: Boolean,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    fetchSrcmApi({
      api: `/connect/v3/sectors/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {},
    }).then((data) => {
      setIsLoading(false);
      const sectorsArr = data?.results.filter((item) => {
        return item.active === true;
      });
      const optionsArr = sectorsArr.map((item) => {
        return { key: item.id, text: item.name };
      });
      const obj = { key: "", text: "Select Sector" };
      optionsArr.unshift(obj);
      setSectorData(optionsArr);
    });
  }, [fetchSrcmApi]);

  useEffect(() => {
    setIsLoading(true);
    fetchSrcmApi({
      api: `/connect/v3/organizations/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        // active: true,
        // page: `${page}`,
        page_size: 400,
      },
    }).then((data) => {
      setIsLoading(false);
      const optionsArr = data?.results.map((item) => {
        return { name: item.id, label: item.name };
      });

      setOrganizations(optionsArr);
    });
  }, [fetchSrcmApi]);

  const fetchUser = (input) => {
    if (input === "") {
      setUserEmailSearch(input);
      setUserPhoneSearch(input);
    }
    if (validateEmail(input)) {
      setUserEmailSearch(input);
    } else if (validatePhoneNumber(input)) {
      setUserPhoneSearch(input);
    }
  };

  const fetchCoordinator = (input) => {
    if (input === "") {
      setCoordinatorAbhyasiSearch(input);
      setCoordinatorEmailSearch(input);
      setCoordinatorPhoneSearch(input);
    }
    if (validateAbhyasiId(input)) {
      setCoordinatorAbhyasiSearch(input.toUpperCase());
    } else if (validateEmail(input)) {
      setCoordinatorEmailSearch(input);
    } else if (validatePhoneNumber(input)) {
      setCoordinatorPhoneSearch(input);
    }
  };

  const fetchSearchedData = useCallback(() => {
    setIsLoading(true);
    fetchSrcmApi({
      api: `/connect/v3/events/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        reporting_only: null,
        page: `${page}`,
        page_size: `${pageSize}`,
        location: citySearch,
        name__icontains: nameSearch,
        sector: sectorSearch,
        organization: organizationSearch,
        start_date__gte: startDateSearch,
        end_date__lte: endDateSearch,
        mobile: userPhoneSearch,
        email: userEmailSearch,
        coordinator_abhyasi_id: coordinatorAbhyasiSearch,
        coordinator_email: coordinatorEmailSearch,
        coordinator_mobile: coordinatorPhoneSearch,
      },
    }).then((data) => {
      setIsLoading(false);
      setFiltersData(data);
      setCountData(data);
    });
  }, [
    citySearch,
    coordinatorAbhyasiSearch,
    coordinatorEmailSearch,
    coordinatorPhoneSearch,
    endDateSearch,
    fetchSrcmApi,
    nameSearch,
    organizationSearch,
    page,
    pageSize,
    sectorSearch,
    startDateSearch,
    userEmailSearch,
    userPhoneSearch,
  ]);

  useEffect(() => {
    if (
      citySearch !== "" ||
      nameSearch !== "" ||
      sectorSearch !== "" ||
      organizationSearch !== "" ||
      startDateSearch !== "" ||
      endDateSearch !== "" ||
      userEmailSearch !== "" ||
      userPhoneSearch !== "" ||
      coordinatorAbhyasiSearch !== "" ||
      coordinatorEmailSearch !== "" ||
      coordinatorPhoneSearch !== ""
    ) {
      fetchSearchedData();
    } else if (
      citySearch === "" &&
      nameSearch === "" &&
      sectorSearch === "" &&
      organizationSearch === "" &&
      startDateSearch === "" &&
      endDateSearch === "" &&
      userEmailSearch === "" &&
      userPhoneSearch === "" &&
      coordinatorAbhyasiSearch === "" &&
      coordinatorEmailSearch === "" &&
      coordinatorPhoneSearch === ""
    ) {
      setFiltersData([]);
      setCountData([]);
    }
  }, [
    citySearch,
    coordinatorAbhyasiSearch,
    coordinatorEmailSearch,
    coordinatorPhoneSearch,
    endDateSearch,
    fetchSearchedData,
    nameSearch,
    organizationSearch,
    page,
    pageSize,
    sectorSearch,
    startDateSearch,
    userEmailSearch,
    userPhoneSearch,
  ]);

  return (
    <React.Fragment>
      <Flex
        sx={{
          alignItems: "flex-start",
          flexDirection: "column",
          px: 2,
          py: 1,
        }}
      >
        <Label
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            fontFamily: "Segoe Ui",
            marginTop: "20px",
            marginBottom: "20px",
            // paddingTop: "5px",
            // paddingBottom: "5px",
          }}
        >
          Search by any of the below fields.
        </Label>
        <Flex sx={{ alignItems: "center" }}>
          <Box>
            <Label
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Segoe Ui",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Event Name
            </Label>
            <Input
              name="name"
              type="text"
              sx={eventsSearchStyle.eventsSearchStyle}
              value={nameSearch}
              onChange={(e) => {
                setPage(1);
                setNameSearch(e.target.value);
              }}
            />
          </Box>
          <Dropdown
            style={{ minWidth: "180px" }}
            options={sectorData}
            sx={{ mr: 2 }}
            label="Sector"
            placeholder="Select Sector"
            onChange={(e, v) => {
              setPage(1);
              setSectorSearch(v.key);
            }}
          />
          <DynamicField
            name="organizations"
            sx={{ mr: 2 }}
            type="autosuggest"
            options={organizations}
            label="Organization"
            value={organizationSearch}
            onChange={(e) => {
              setPage(1);
              setOrganizationSearch(e);
            }}
            props={{
              placeholder: "",
              required: false,
            }}
          />
        </Flex>
        <Flex sx={{ mt: "10px" }}>
          <Box>
            <Label
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Segoe Ui",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Start Date {`(>=)`}
            </Label>
            <Input
              name="start_date"
              sx={eventsSearchStyle.eventsSearchStyle}
              type="date"
              onChange={(e) => {
                setPage(1);
                setStartDateSearch(e.target.value);
              }}
              props={{
                placeholder: "Start Date",
                required: false,
              }}
            />
          </Box>
          <Box>
            <Label
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Segoe Ui",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              End Date {`(<=)`}
            </Label>
            <Input
              name="End Date"
              sx={eventsSearchStyle.eventsSearchStyle}
              type="date"
              onChange={(e) => {
                setPage(1);
                setEndDateSearch(e.target.value);
              }}
              props={{
                placeholder: "",
                required: false,
              }}
            />
          </Box>
          <DynamicField
            name="city"
            sx={{ mt: "6px" }}
            label="City"
            type="srcm_city_id"
            value={citySearch}
            onChange={(e) => {
              setPage(1);
              setCitySearch(e);
            }}
            props={{
              placeholder: "City",
              required: false,
            }}
          />
        </Flex>
        <Flex sx={{ mt: "10px", alignItems: "center", gap: "8px" }}>
          <DynamicField
            name="user"
            sx={{ mr: 2 }}
            type="text"
            label="Created By User"
            value={userInput}
            onChange={(e) => {
              setPage(1);
              setUserInput(e);
              fetchUser(e);
            }}
            props={{
              placeholder: "Email/Phone",
              required: false,
            }}
          />
          <DynamicField
            name="hfnCoordinator"
            sx={{ mr: 2 }}
            type="text"
            label="HFN Coordinator"
            value={coordinatorInput}
            onChange={(e) => {
              setPage(1);
              setCoordinatorInput(e);
              fetchCoordinator(e);
            }}
            props={{
              placeholder: "Abhyasi ID/Email/Phone",
              required: false,
            }}
          />
        </Flex>
      </Flex>

      <Box>
        <Pagination
          page={page - 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          TotalPageCount={countData?.count}
          setPage={setPage}
        />
        {isLoading === true ? (
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "40px 0",
              svg: {
                mt: "8px",
                mr: "4px",
              },
            }}
          >
            <Loader type="Oval" color="grey" width={30} height={25} />
            Loading..
          </div>
        ) : (
          filtersData &&
          filtersData.results &&
          filtersData.results.length && (
            <div style={{ marginBottom: "20px" }}>
              <DetailsList
                columns={sectorInitColumns}
                items={filtersData.results}
                setKey="set"
                selectionMode={SelectionMode.none}
                onRenderItemColumn={renderSectorItemColumn}
                compact
                enableUpdateAnimations
              />
            </div>
          )
        )}
      </Box>
    </React.Fragment>
  );
};

DynamicSearch.propTypes = {
  renderSectorItemColumn: PropTypes.func.isRequired,
};

export default React.memo(DynamicSearch);
