/** @jsx jsx */
import React, { useState, useEffect, useCallback } from "react";
import { jsx, Flex, Box } from "theme-ui";
import PropTypes, { number } from "prop-types";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { get } from "lodash";
import {
  ActionButton,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogType,
  Dropdown,
  SelectionMode,
} from "office-ui-fabric-react";
import Pagination from "../../utils/pagination";
import { appsDialogStyles } from "../../utils/dialogStyles";
import ProgramTypeAddForm from "../ProgramTypeAddForm";

const ProgramTypeManagement = ({ renderSectorItemColumn }) => {
  const [addProgramTypeDialog, setAddProgramTypeDialog] = useState(false);
  const [editTemplate, setEditTemplate] = useState({});
  const openAddProgramTypeDialog = () => setAddProgramTypeDialog(true);
  const [programType, setprogramType] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { fetchSrcmApi } = useFetchSrcmApi();

  // filterdata
  const [filtersData, setFiltersData] = useState([{ key: "All", text: "All" }]);
  const [methodParams, setMethodParams] = useState();

  const closeAddProgramTypeDialog = () => {
    setEditTemplate({});
    setAddProgramTypeDialog(false);
  };

  const programInitColumns = [
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sector",
      fieldName: "sector",
      name: "Sector",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sequence",
      fieldName: "sequence",
      name: "Sequence",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "active",
      fieldName: "active",
      name: "Status",
      data: Boolean,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddProgramTypeDialog();
            }}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    fetchSrcmApi({
      api: `/connect/v3/sectors/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        active: true,
        page_size: 1000,
      },
    }).then((data) => {
      const optionsArr = data.results.map((item) => {
        return { key: item.id, text: item.name };
      });
      setFiltersData((prevState) => [...prevState, ...optionsArr]);
    });
  }, [fetchSrcmApi]);

  const getProgramTypeManagement = useCallback(() => {
    fetchSrcmApi({
      api: `/connect/v3/program-types/`,
      client: "eventsClient",
      method: "GET",
      methodParams: {
        ...methodParams,
        page: `${page}`,
        page_size: `${pageSize}`,
      },
    }).then((data) => {
      setprogramType(data);
    });
  }, [fetchSrcmApi, pageSize, page, methodParams]);

  useEffect(() => {
    getProgramTypeManagement();
  }, [getProgramTypeManagement]);

  const onChange = (e, v) => {
    if (!(v.key === "All")) {
      setMethodParams({ sector: v.key });
    } else {
      setMethodParams();
    }
    setPage(1);
  };
  return (
    <>
      <Box sx={{ p: 2, textAlign: "right" }}>
        <DefaultButton
          styles={buttonStyles.blueLight}
          onClick={openAddProgramTypeDialog}
        >
          Add Program{" "}
        </DefaultButton>
      </Box>
      <Box>
        <Pagination
          page={page - 1}
          pageSize={pageSize}
          setPageSize={setPageSize}
          TotalPageCount={programType.count}
          setPage={setPage}
        />
        <Dropdown
          style={{ maxWidth: "150px" }}
          options={filtersData}
          sx={{ ml: 2 }}
          placeholder="Filter by Sector"
          onChange={onChange}
        />
        {programType && programType.results && programType.results.length ? (
          <DetailsList
            columns={programInitColumns}
            items={programType.results}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderSectorItemColumn}
            compact
            enableUpdateAnimations
          />
        ) : (
          ""
        )}
      </Box>
      <Dialog
        isOpen={addProgramTypeDialog}
        type={DialogType.close}
        onDismiss={closeAddProgramTypeDialog}
        title={get(editTemplate, "id") ? "Edit Program " : "Add Program "}
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <ProgramTypeAddForm
            refreshTemplates={getProgramTypeManagement}
            closeModal={closeAddProgramTypeDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>
    </>
  );
};

ProgramTypeManagement.propTypes = {
  renderSectorItemColumn: PropTypes.func.isRequired,
};

export default React.memo(ProgramTypeManagement);
